import { FilterDataType } from '@/enums/FilterDataType.enum';
import { FilterVisualization } from '@/enums/FilterVisualization.enum';
import { FilterDataVisualizationButton } from '@/models/FilterDataVisualizationButton.model';
import { convertSnakeToCamelCase, falsy, t } from '@/utils/utils';
import { Filters, FilterVal } from '@/models/Filters.model';
import { KeyName } from '@/models/KeyName.model';
import { FilterVariable } from '@/models/FilterVariable.model';
import { FilterSeason } from '@/enums/FilterSeason.enum';
import { MapParams } from '@/api/endpoints/map/map.types';
import { DOTS_PER_INCH, INCHES_PER_METRE, NULL, NULL_PLACEHOLDER } from '@/utils/constants.utils';
import { Fill, Stroke, Style } from 'ol/style';
import { Feature } from 'ol';
import { getVectorContext } from 'ol/render';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { MapFiltersState } from '@/models/MapFiltersState.model';
import { Area } from '@/enums/Area.enum';
import { i18n } from '@/localization/localization';
import Units, { METERS_PER_UNIT } from 'ol/proj/Units';

export function getFilterDataVisualizationButtons(): Array<FilterDataVisualizationButton> {
	const obsPro = `${FilterDataType.OBS}${FilterVisualization.PRO}`;
	const obsVre = `${FilterDataType.OBS}${FilterVisualization.VRE}`;

	const modPro = `${FilterDataType.MOD}${FilterVisualization.PRO}`;
	const modVre = `${FilterDataType.MOD}${FilterVisualization.VRE}`;

	return [
		new FilterDataVisualizationButton({
			selected: obsVre,
			dataType: FilterDataType.OBS,
			visualization: FilterVisualization.VRE,
			label: t(`filters.dataVisualisation.${obsVre}`),
		}),
		new FilterDataVisualizationButton({
			selected: obsPro,
			dataType: FilterDataType.OBS,
			visualization: FilterVisualization.PRO,
			label: t(`filters.dataVisualisation.${obsPro}`),
		}),
		new FilterDataVisualizationButton({
			selected: modVre,
			dataType: FilterDataType.MOD,
			visualization: FilterVisualization.VRE,
			label: t(`filters.dataVisualisation.${modVre}`),
		}),
		new FilterDataVisualizationButton({
			selected: modPro,
			dataType: FilterDataType.MOD,
			visualization: FilterVisualization.PRO,
			label: t(`filters.dataVisualisation.${modPro}`),
		}),
	];
}

export function getFilterObservations(
	list: FilterDataVisualizationButton[]
): FilterDataVisualizationButton[] {
	return list.filter((btn) => btn.dataType === FilterDataType.OBS);
}

export function getFilterProjections(
	list: FilterDataVisualizationButton[]
): FilterDataVisualizationButton[] {
	return list.filter((btn) => btn.dataType === FilterDataType.MOD);
}

export function convertVariableDictToList(dict: Record<string, KeyName[]>): FilterVariable[] {
	if (falsy(dict)) {
		return [];
	}

	const mapFn = ([type, list]: [string, KeyName[]]) => new FilterVariable({ type, list });
	return Object.entries(dict).map(mapFn);
}

export function getSelectedDataVisualizationKey(filters: Filters): string {
	if (!filters) {
		return null;
	}
	const { dataType } = filters;
	const { visualization } = filters;
	return `${dataType?.selected}${visualization?.selected}`;
}

export const getSelectedDataVisualizationLabel = ({
	state,
	regionName,
}: {
	state: MapFiltersState;
	regionName?: string;
}): string => {
	const dataObj = state.dataVisualizationList.find(
		(d) => d.selected === state.dataVisualizationSelected
	);
	const dataLabel = dataObj.label;

	const forLabel = t('word.for');
	const areaObj = state.areaList.find((a) => {
		return a.value === state.areaSelected;
	});
	const areaLabel = t(`filters.area.${areaObj?.value}Summary`);
	const name = convertSnakeToCamelCase({
		str: regionName?.toLowerCase(),
		separator: ' ',
	});

	const regionLabel = regionName ? ` ${forLabel} ${areaLabel} ${name}` : '';

	return `${dataLabel}${regionLabel}`;
};

export function getSelectedVariableLabel(selected: KeyName): string {
	if (!selected?.name) {
		return '';
	}

	return t(`filters.variable.${selected.name}Title`);
}

export function getSelectedSeasonLabel(selected: FilterSeason): string {
	if (!selected) {
		return '';
	}
	return t(`filters.season.${selected}`);
}

export const getFilterVal = (value: FilterVal): unknown => {
	if (!value) {
		return null;
	}
	return typeof value === 'object' ? value.key : value;
	// If u change name => key, also change getSelected fn in index.mock.ts
};

const FILTER_PARAMS = 'filterParams';

/**
 * Saves filter params in local storage,
 * so the params can be preserved even if a user is changing the routes
 *
 * @param obj
 */
export const saveFilterParams = (obj: MapParams): void => {
	localStorage.setItem(FILTER_PARAMS, JSON.stringify(obj));
};

/**
 * Gets filter params from local storage
 */
export const getFilterParams = (): MapParams => {
	const filterParamsStr = localStorage.getItem(FILTER_PARAMS);
	if (!filterParamsStr || filterParamsStr === NULL) {
		return null;
	}
	const filterParams = JSON.parse(filterParamsStr) as MapParams;
	return falsy(filterParams) ? null : filterParams;
};

/**
 *
 * Set style with color from colorArray based on index from feature prop.
 *
 * @param {Array} colorArray - array of rgb color vals, (eg.) [ "120,10,10", "0, 255, 0",...]
 * @param {Feature} feature - that is getting style
 * @param {string} propIndexName - default: 'index' - name of prop that represents index of feature
 * @param {Stroke} stroke - default: color of fill
 * @param {number} strokeWidth - default: 1
 *
 * @return {Style} - Style
 */
export function getStyleForFeature({
	colorArray,
	feature,
	propIndexName = 'index',
	stroke,
	strokeWidth = 2,
}: {
	colorArray: string[];
	feature: Feature;
	propIndexName?: string;
	stroke?: Stroke;
	strokeWidth?: number;
}): Style {
	const i = feature.get(propIndexName);

	if (!colorArray[i]) {
		return null;
	}

	const color = colorArray[i] ? `rgba(${colorArray[i]})` : '#fff';
	const fill = new Fill({
		color,
	});
	// eslint-disable-next-line no-param-reassign
	stroke =
		stroke ||
		new Stroke({
			color,
			width: strokeWidth,
		});

	feature.setProperties({ rgbColor: colorArray[i] });
	feature.setProperties({ color });

	return new Style({
		fill,
		stroke,
	});
}

/**
 * Clips everything out of the borders of maskLayer & shows clippedLayer inside.
 *
 * @param clippedLayer
 * @param maskLayer
 */
export function setClippingLayer({
	clippedLayer,
	maskLayer,
}: {
	clippedLayer: VectorLayer;
	maskLayer: VectorLayer;
}): void {
	// Doesn't affects any style, but needs to exist
	const style = new Style({
		fill: new Fill({
			color: '#000',
		}),
	});

	maskLayer.getSource().on('addfeature', () => {
		clippedLayer.setExtent(maskLayer.getSource().getExtent());
	});

	clippedLayer.on('postrender', (e) => {
		const vectorContext = getVectorContext(e);
		e.context.globalCompositeOperation = 'destination-in';
		maskLayer.getSource().forEachFeature((feature) => {
			vectorContext.drawFeature(feature, style);
		});
		e.context.globalCompositeOperation = 'source-over';
	});
}

/**
 *  Generates line pattern for hatching layers
 */
export function generateLinePattern(): CanvasPattern {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	const pattern = (function () {
		context.strokeStyle = '#000';

		let step = 0;
		for (let i = 0; i < 15; i += 1) {
			step = i * 20;
			context.beginPath();
			context.moveTo(step, 0);
			context.lineTo(300 + step, 150);
			context.stroke();

			context.beginPath();
			context.moveTo(-step, 0);
			context.lineTo(300 - step, 150);
			context.stroke();
		}
		return context.createPattern(canvas, 'repeat');
	})();

	return pattern;
}

/**
 * Converts map to canvas so it can be used for download of image or pdf.
 *
 * @param map
 */
export function convertMapToCanvas(map: Map): HTMLCanvasElement {
	const mapCanvas = document.createElement('canvas');
	const size = map.getSize();
	[mapCanvas.width, mapCanvas.height] = size;
	const mapContext = mapCanvas.getContext('2d');

	Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), (canvas) => {
		if (canvas.width > 0) {
			const { opacity } = canvas.parentNode.style;
			mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
			const { transform } = canvas.style;
			// Get the transform parameters from the style's transform matrix
			const matrix = transform
				.match(/^matrix\(([^(]*)\)$/)[1]
				.split(',')
				.map(Number);
			// Apply the transform to the export map context
			CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
			mapContext.drawImage(canvas, 0, 0);
		}
	});

	return mapCanvas;
}

/**
 * Trims transparent pixels from canvas, so when downloading image, it is smaller.
 *
 * @param canvas
 */
export function trimCanvas(canvas: HTMLCanvasElement) {
	const ctx = canvas.getContext('2d');
	const copy = document.createElement('canvas').getContext('2d');
	const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
	const l = pixels.data.length;
	let i;
	const bound: Record<string, number> = {
		top: null,
		left: null,
		right: null,
		bottom: null,
	};
	let x;
	let y;

	// Iterate over every pixel to find the highest
	// and where it ends on every axis ()
	for (i = 0; i < l; i += 4) {
		if (pixels.data[i + 3] !== 0) {
			x = (i / 4) % canvas.width;
			// eslint-disable-next-line no-bitwise
			y = ~~(i / 4 / canvas.width);

			if (bound.top === null) {
				bound.top = y;
			}

			if (bound.left === null) {
				bound.left = x;
			} else if (x < bound.left) {
				bound.left = x;
			}

			if (bound.right === null) {
				bound.right = x;
			} else if (bound.right < x) {
				bound.right = x;
			}

			if (bound.bottom === null) {
				bound.bottom = y;
			} else if (bound.bottom < y) {
				bound.bottom = y;
			}
		}
	}

	// Calculate the height and width of the content
	const trimHeight = bound.bottom - bound.top;
	const trimWidth = bound.right - bound.left;
	const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

	copy.canvas.width = trimWidth;
	copy.canvas.height = trimHeight;
	copy.putImageData(trimmed, 0, 0);

	// Return trimmed canvas
	return copy.canvas;
}

export function getSelectedPeriodLabel(periodSelectedNames: KeyName[]): string {
	return periodSelectedNames
		.map((val) => val?.name)
		.filter((val) => val !== undefined)
		.map((val) => val ?? NULL_PLACEHOLDER)
		.join(' / ');
}

/**
 * Formats filters values to state.
 * State is used for showing filter values
 *
 * @param filters
 * @param state
 */
export const formatFiltersToState = ({
	filters,
	state,
}: {
	filters: Filters;
	state: MapFiltersState;
}): MapFiltersState => {
	if (falsy(filters)) {
		return state;
	}

	state.dataVisualizationSelected = getSelectedDataVisualizationKey(filters);
	state.dataVisualizationList = getFilterDataVisualizationButtons();

	state.observationList = getFilterObservations(state.dataVisualizationList);
	state.projectionList = getFilterProjections(state.dataVisualizationList);

	state.variablesSelected = filters?.variableUuid?.selected;
	const availableVars = filters?.variableUuid?.available;
	state.variables = convertVariableDictToList(availableVars);
	state.variablesSelectedLabel = getSelectedVariableLabel(state.variablesSelected);
	state.variablesIsDisabled = !state.variables?.length;

	state.basePeriodSelected = filters?.basePeriodUuid?.selected;
	state.basePeriodList = filters?.basePeriodUuid?.available ?? [];

	state.changePeriodSelected = filters?.changePeriodUuid?.selected;
	state.changePeriodList = filters?.changePeriodUuid?.available ?? [];

	state.scenarioSelected = filters?.scenarioUuid?.selected;
	state.scenarioList = filters?.scenarioUuid?.available ?? [];

	const periodSelectedList = [
		state.basePeriodSelected,
		state.changePeriodSelected,
		state.scenarioSelected,
	];

	state.periodSelectedLabel = getSelectedPeriodLabel(periodSelectedList);

	state.periodIsDisabled =
		!state.basePeriodList?.length && !state.changePeriodList?.length && !state.scenarioList?.length;

	state.heatingSelected = filters?.heating?.selected;
	state.heatingList = filters?.heating?.available ?? [];
	state.heatingSelectedLabel = state.heatingSelected ? `${state.heatingSelected}°C` : '';
	state.heatingIsDisabled = !state.heatingList?.length;

	state.seasonSelected = filters?.season?.selected;
	state.seasonList = filters?.season?.available ?? [];
	state.seasonSelectedLabel = getSelectedSeasonLabel(state.seasonSelected);
	state.seasonIsDisabled = !state.seasonList?.length;

	return state;
};

export const updateAreaState = ({
	areaToShow,
	state,
}: {
	areaToShow: Area;
	state: MapFiltersState;
}): MapFiltersState => {
	state.areaSelected = areaToShow;
	state.areaSelectedLabel = t(`filters.area.${areaToShow}`);

	return state;
};

/**
 * Gets first feature from Layer by pixel
 * and if it doesn't find feature it will move pixel coords and try again.
 *
 * @param pixel
 * @param layer
 * @param movePixelBy
 */
export const getFirstFeatureByPixel = async ({
	pixel,
	layer,
	movePixelBy = [],
}: {
	pixel: number[];
	layer: VectorLayer;
	movePixelBy?: number[];
}): Promise<Feature> => {
	const squareFeatList = await layer.getFeatures(pixel);
	const [firstSquare] = squareFeatList;

	if (firstSquare || !movePixelBy?.length) {
		return firstSquare;
	}

	const n = movePixelBy.shift();
	const p = pixel.map((x) => x + n);

	return getFirstFeatureByPixel({
		pixel: p,
		layer,
		movePixelBy,
	});
};

/**
 * Formats CSV name to more user friendly name.
 * (eg.)
 * name => result
 * "mod_tas_vre_1951-2100_na_rcp45_na_g_ens_bra.csv" => "Srednja dnevna temperatura (tas) - za period 1951-2100 i za scenario RCP45"
 * "obs_tas_pro_1961-1990_1950-2020_na_na_g_eobs_bra.csv" => "Srednja dnevna temperatura (tas) - odstupanje za period 1950-2020 u odnosu na 1961-1990"
 *
 * @param name
 */
export const formatCSVName = (name: string): string => {
	if (!name) {
		return '';
	}

	// eslint-disable-next-line prefer-const
	let [data, variable, viz, basePeriod, changePeriod, scenario, , season, model, shortName] =
		name.split('.').shift().split('_') || [];

	variable = t(`filters.variable.${variable}Title`);

	if (viz === FilterVisualization.VRE) {
		viz = `${t('mapDetails.chart.forPeriod')} ${basePeriod}`;
	}
	if (viz === FilterVisualization.PRO) {
		changePeriod = `${t('mapDetails.chart.deviationForPeriod')} ${changePeriod}`;
		basePeriod = `${t('mapDetails.chart.relativeTo')} ${basePeriod}`;
		viz = `${changePeriod} ${basePeriod}`;
	}

	if (data === FilterDataType.MOD) {
		scenario = ` ${t('mapDetails.chart.forScenario')} ${scenario.toUpperCase()}`;
	} else {
		// if there is not scenario in name it value will be: 'na', so we are setting it to ''.
		scenario = '';
	}

	return `${variable} - ${viz}${scenario}`;
};

/**
 * Gets name form feature for right local (cir, lat or eng).
 *
 * @param feat
 */
export const getFeatureName = (feat: Feature): string => {
	return feat?.get('name')?.[i18n.global.locale] || '';
};

/**
 * Returns inches pre unit on map.
 * Used for calculating how much map scaled.
 *
 * @param unit
 */
export function inchesPreUnit(unit: Units): number {
	return METERS_PER_UNIT[unit] * INCHES_PER_METRE;
}

/**
 * Returns map ratio to real size.
 *
 * @param map
 * @param toRound
 */
export function mapRatioScale({ map, toRound = true }: { map: Map; toRound?: boolean }): number {
	const resolution = map.getView().getResolution();
	const unit = map.getView().getProjection().getUnits();

	let scale = resolution * inchesPreUnit(unit) * DOTS_PER_INCH;
	scale = toRound ? Math.round(scale) : scale;

	return scale;
}
