
import { CSSProperties, defineComponent, PropType, reactive, ref, toRefs, watch } from 'vue';
import CGrid from '@/components/shared/CGrid.vue';
import { Filters, FilterVal } from '@/models/Filters.model';
import { formatFiltersToState, getFilterVal, updateAreaState } from '@/utils/map.utils';
import { capitalizeWord } from '@/utils/utils';
import CButton from '@/components/shared/CButton.vue';
import { ButtonType } from '@/enums/ButtonType.enum';
import CButtonRadio from '@/components/shared/CButtonRadio.vue';
import { FilterName } from '@/enums/FilterName.enum';
import { FilterDataVisualizationButton } from '@/models/FilterDataVisualizationButton.model';
import { MapFiltersState } from '@/models/MapFiltersState.model';
import { Area } from '@/enums/Area.enum';
import { CButtonRadioModel } from '@/models/CButtonRadio.model';
import { FILTER_INIT_STATE, getAreaButtonList } from '@/utils/constants.utils';
import CDrawer from '@/components/shared/CDrawer.vue';
import CIconLeftRight from '@/components/shared/CIconLeftRight.vue';
import CInfoRow from '@/components/shared/CInfoRow.vue';
import CSearch from '@/components/shared/CSearch.vue';
import { FeatureSelect } from '@/models/FeatureSelect';
import { TourSelector } from '@/enums/TourSelector.enum';
import CInput from '@/components/shared/CInput.vue';
import { LonLat } from '@/models/LonLat.model';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TourStepId } from '@/enums/TourStepId.enum';

enum ComponentEvent {
	SELECT = 'select',
	SELECT_AREA = 'selectArea',
	SEARCH = 'search',
	SELECT_SEARCH = 'selectSearch',
	UNSELECT_SEARCH = 'unselectSearch',
	SEARCH_POINT = 'searchPoint',
	START_TOUR = 'startTour',
	SHOW_TOUR_STEP = 'showTourStep',
	CHANGE_STATE = 'changeState',
}

const filtersPriority = [
	FilterName.DATA,
	FilterName.VISUALIZATION,
	FilterName.VARIABLE_UUID,
	FilterName.BASE_PERIOD_UUID,
	FilterName.CHANGE_PERIOD_UUID,
	FilterName.SCENARIO_UUID,
	FilterName.HEATING,
	FilterName.SEASON,
];

const isVisibleDictInit = (() => {
	const obj = {} as Record<string, boolean>;
	Object.values(FilterName).forEach((name) => {
		obj[name] = false;
	});
	return obj;
})();

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapFilters',
	components: { CInput, CSearch, CInfoRow, CIconLeftRight, CDrawer, CButtonRadio, CButton, CGrid },
	props: {
		filters: {
			required: true,
			type: Object as PropType<Filters>,
		},
		areaToShow: {
			required: false,
			type: String as PropType<Area>,
		},
		term: {
			required: false,
			type: String,
			default: () => '',
		},
		lat: {
			required: false,
			type: Number,
			default: () => null,
		},
		lon: {
			required: false,
			type: Number,
			default: () => null,
		},
		searchResults: {
			required: false,
			type: Array,
			default: () => [],
		},
		toShowResults: {
			required: false,
			type: Boolean,
			default: () => true,
		},
	},
  emits: [
    ComponentEvent.SELECT,
    ComponentEvent.SELECT_AREA,
    ComponentEvent.SEARCH,
    ComponentEvent.SELECT_SEARCH,
    ComponentEvent.UNSELECT_SEARCH,
    ComponentEvent.SEARCH_POINT,
    ComponentEvent.START_TOUR,
    ComponentEvent.SHOW_TOUR_STEP,
    ComponentEvent.CHANGE_STATE,
  ],
	setup(props, context) {
		let filters = reactive<Filters>(props.filters as Filters);
		// Is used for showing data in template
		let state = reactive<MapFiltersState>(FILTER_INIT_STATE);
		const showDrawer = ref<boolean>(false);
		const isVisibleDict = ref<Record<string, boolean>>({ ...isVisibleDictInit });

		const lonCopy = ref<number>(props.lon as number);
		const latCopy = ref<number>(props.lat as number);

		state = formatFiltersToState({ filters, state });
		state.areaList = getAreaButtonList();
		state = updateAreaState({ areaToShow: props.areaToShow as Area, state });

		/**
		 * Set all on false & toggles only one filter, because only one filter can be visible in drawer.
		 */
		const toggleVisibilityFilter = (filterName: FilterName): void => {
			const newValue = !isVisibleDict.value[filterName];
			isVisibleDict.value = { ...isVisibleDictInit };
			isVisibleDict.value[filterName] = newValue;

			showDrawer.value = newValue;
		};

		const setVisibilityFilter = (filterName: FilterName, value: boolean): void => {
			isVisibleDict.value = { ...isVisibleDictInit };
			isVisibleDict.value[filterName] = value;

			showDrawer.value = value;
		};

		const closeFilterDrawer = (): void => {
			isVisibleDict.value = { ...isVisibleDictInit };
			showDrawer.value = false;
		};

		watch(
			() => props.filters,
			(val) => {
				filters = reactive<Filters>(val as Filters);
				state = reactive<MapFiltersState>(formatFiltersToState({ filters, state }));
				context.emit(ComponentEvent.CHANGE_STATE, state);
			}
		);

		watch(
			() => props.areaToShow,
			(val) => {
				state = updateAreaState({ areaToShow: val as Area, state });
				context.emit(ComponentEvent.CHANGE_STATE, state);
			}
		);

		watch(
			() => props.lon,
			(val) => {
				lonCopy.value = val as number;
			}
		);

		watch(
			() => props.lat,
			(val) => {
				latCopy.value = val as number;
			}
		);

		const gridStyle: CSSProperties = { flexDirection: 'column', alignItems: 'flex-start' };

		return {
			Area,
			TourSelector,
			TourStepId,
			faInfoCircle,
			capitalizeWord,
			FilterName,
			ComponentEvent,
			sendObj: {} as Record<string, unknown>,
			gridMargin: '16px',
			gridStyle,
			ButtonType,
			lonCopy,
			latCopy,
			...toRefs(state),
			showDrawer,
			isVisibleDict,
			toggleVisibilityFilter,
			setVisibilityFilter,
			closeFilterDrawer,
		};
	},
	data() {
		return {
			latError: null,
			lonError: null,
		};
	},
	computed: {
		filtersSelected(): Record<string, any> {
			const obj: Record<string, any> = {};
			Object.entries(this.filters || {}).forEach(([key, value]) => {
				obj[key] = value?.selected;
			});
			return obj;
		},
	},
	methods: {
		/**
		 * When filter is selected, only that filter & filters with higher priority will be send
		 *
		 * @param name
		 * @param value
		 */
		selectFilter(name: string, value: FilterVal): void {
			this.sendObj = {};
			let val: FilterVal;
			let filterVal: unknown;

			// Getting first one in filtersPriority
			let [currentFilter] = filtersPriority;
			// Going down from fist to selected filter & adding values to send object
			for (let i = 0; currentFilter !== name; i += 1, currentFilter = filtersPriority[i]) {
				val = this.filtersSelected[currentFilter];
				filterVal = getFilterVal(val);
				this.sendObj[currentFilter] = filterVal;
			}
			// Adding selected filter
			this.sendObj[name] = getFilterVal(value);

			this.$emit(ComponentEvent.SELECT, this.sendObj);
		},
		/**
		 * This is the highest priority filter & has 2 values, so only these two values will be sent.
		 *
		 * @param value
		 */
		selectDataVisualization(value: FilterDataVisualizationButton): void {
			this.sendObj = {
				[FilterName.DATA]: value.dataType,
				[FilterName.VISUALIZATION]: value.visualization,
			};
			this.$emit(ComponentEvent.SELECT, this.sendObj);
		},
		selectArea(item: CButtonRadioModel): void {
			this.$emit(ComponentEvent.SELECT_AREA, item);
		},
		selectRegion(event: FeatureSelect): void {
			if (!event?.feature) {
				return;
			}
			this.$emit(ComponentEvent.SELECT_SEARCH, event);
		},
		searchPoint({ lat, lon }: { lat: string | number; lon: string | number }): void {
			this.latError = !lat ? this.$t('message.requiredOption') : '';
			this.lonError = !lon ? this.$t('message.requiredOption') : '';

			if (!lat || !lon) {
				return;
			}

			this.$emit(ComponentEvent.SEARCH_POINT, new LonLat({ lat, lon }));
		},
	},
});
