
import { defineComponent, PropType, ref, watch } from 'vue';
import { KeyName } from '@/models/KeyName.model';
import { TourSelector } from '@/enums/TourSelector.enum';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

enum ComponentEvent {
	SELECT = 'select',
	UNSELECT = 'unselect',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CSearch',
	props: {
		value: {
			required: false,
			type: String,
			default: () => '',
		},
		autocompleteResults: {
			required: false,
			type: Array as PropType<KeyName[]>,
			default: () => [],
		},
		toShowResults: {
			required: false,
			type: Boolean,
			default: () => true,
		},
		dataTour: {
			required: false,
			type: String as PropType<TourSelector>,
			default: () => '',
		},
	},
	setup(props, context) {
		const results = ref<KeyName[]>(props.autocompleteResults as KeyName[]);
		const isFocused = ref<boolean>(false);
		const term = ref<string>(props.value as string);
		const showResults = ref<boolean>(props.toShowResults as boolean);

		watch(
			() => props.autocompleteResults as KeyName[],
			(value) => {
				results.value = value;
			}
		);

		watch(
			() => props.value as string,
			(value) => {
				term.value = value;
				if (value === '') {
					context.emit(ComponentEvent.UNSELECT);
				}
			}
		);

		watch(
			() => props.toShowResults as boolean,
			(show) => {
				showResults.value = show;
			}
		);

		const onSelect = (item: KeyName): void => {
			term.value = item.name;
			context.emit(ComponentEvent.SELECT, item);
		};

		const onUnselect = (): void => {
			term.value = '';
			context.emit(ComponentEvent.UNSELECT);
		};

		const onFocusIn = () => {
			isFocused.value = true;
			showResults.value = true;
		};

		return {
			ComponentEvent,
			faTimes,
			results,
			isFocused,
			term,
			showResults,
			onSelect,
			onUnselect,
			onFocusIn,
			count: 0,
		};
	},
	computed: {
		showAutocomplete(): boolean {
			return this.showResults && !!this.results?.length;
		},
	},
	watch: {
		showResults: {
			immediate: true,
			handler(show) {
				if (show) {
					document.addEventListener('click', this.closeSearch);
				} else {
					document.removeEventListener('click', this.closeSearch);
				}
			},
		},
	},
	beforeUnmount() {
		document.removeEventListener('click', this.closeSearch);
	},
	methods: {
		closeSearch(event: MouseEvent): void {
			const root = this.$refs.root as HTMLElement;
			if (this.count === 0 || root?.contains((event as any).target)) {
				this.count += 1;
				return;
			}
			this.showResults = false;
		},
	},
});
