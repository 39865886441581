
import { defineComponent, ref, watch } from 'vue';
import { faDownload, faMapMarkerAlt, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import CButtonIcon from '@/components/shared/CButtonIcon.vue';
import MapDownloadDropdown from '@/components/map/MapDownloadDropdown.vue';
import { TourSelector } from '@/enums/TourSelector.enum';

enum ComponentEvent {
	ZOOM_IN = 'zoomIn',
	ZOOM_OUT = 'zoomOut',
	SHOW_POPOVER = 'showPopover',
	DOWNLOAD_IMAGE = 'downloadImage',
	DOWNLOAD_PDF = 'downloadPDF',
	DOWNLOAD_FILE = 'downloadFile',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapOptions',
	components: { MapDownloadDropdown, CButtonIcon },
	props: {
		showPopover: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const isShowPopoverActive = ref<boolean>(props.showPopover);
		watch(
			() => props.showPopover,
			(value) => {
				isShowPopoverActive.value = value;
			}
		);

		return {
			TourSelector,
			ComponentEvent,
			faPlus,
			faMinus,
			faMapMarkerAlt,
			faDownload,
			isShowPopoverActive,
		};
	},
	data() {
		return {
			dropdownTriggerActive: false,
		};
	},
});
