export class MapPopupData {
	public title: string;
	public subtitle: string;
	public lat: string | number;
	public lon: string | number;
	public info: string;

	constructor(obj: MapPopupData) {
		this.title = obj.title;
		this.subtitle = obj.subtitle;
		this.lat = obj.lat;
		this.lon = obj.lon;
		this.info = obj.info;
	}
}
