
import { defineComponent, PropType } from 'vue';
import { ButtonIconType } from '@/enums/ButtonIconType.enum';
import { ButtonIconColor } from '@/enums/ButtonIconColor.enum';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CButtonIcon',
	props: {
		iconName: {
			required: true,
			type: String,
		},
		type: {
			required: false,
			type: String as PropType<ButtonIconType>,
			default: () => ButtonIconType.RAISED,
			validator: (value: ButtonIconType) => Object.values(ButtonIconType).includes(value),
		},
		color: {
			required: false,
			type: String as PropType<ButtonIconColor>,
			default: () => ButtonIconColor.BASIC,
			validator: (value: ButtonIconColor) => Object.values(ButtonIconColor).includes(value),
		},
		isActive: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	computed: {
		activeClass(): string {
			return `c-button-icon-${this.color}--active`;
		},
	},
});
