
import { defineComponent } from 'vue';
import { formatNumber, roundNumber } from '@/utils/utils';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapFooter',
	props: {
		lon: {
			required: false,
			type: Number,
			default: () => null,
		},
		lat: {
			required: false,
			type: Number,
			default: () => null,
		},
		regionName: {
			required: false,
			type: String,
			default: () => null,
		},
		ratio: {
			required: false,
			type: Number,
			default: () => null,
		},
	},
	computed: {
		lonLatStr(): string {
			const lat = roundNumber({ num: this.lat as number, decimals: 6 });
			const lon = roundNumber({ num: this.lon as number, decimals: 6 });
			return `N: ${lat} E: ${lon}`;
		},
		ratioStr(): string {
			const num = this.ratio as number;
			return num ? `1: ${formatNumber({ num })}` : '';
		},
	},
});
