/**
 * Order of list: [lon, lat]
 */
export class LonLat {
	public lon: number;
	public lat: number;
	/**
	 * Order of list: [lon, lat]
	 */
	public list: number[];

	constructor({ lon, lat }: { lon: string | number; lat: string | number }) {
		this.lon = Number(lon);
		this.lat = Number(lat);
		this.list = [this.lon, this.lat];
	}
}
