
import { defineComponent, PropType } from 'vue';
import CButtonIcon from '@/components/shared/CButtonIcon.vue';
import { faMapMarker, faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ButtonIconType } from '@/enums/ButtonIconType.enum';
import { MapPopupData } from '@/models/MapPopupData.model';

enum ComponentEvent {
	CLOSE = 'close',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapPopup',
	components: { CButtonIcon },
	props: {
		data: {
			required: true,
			type: Object as PropType<MapPopupData>,
			default: () => ({}),
		},
	},
	setup() {
		return {
			faTimes,
			faMapMarker,
			faMapMarkerAlt,
			ButtonIconType,
		};
	},
	methods: {
		close() {
			this.$emit(ComponentEvent.CLOSE);
		},
	},
});
