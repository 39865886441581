import { TourSelector } from '@/enums/TourSelector.enum';
import Shepherd from 'shepherd.js';
import { t } from '@/utils/utils';

/**
 * Returns data-tour selector for specific name.
 *
 * @param name
 */
export function tourSelect(name: TourSelector): string {
	return `[data-tour=${name}]`;
}

/**
 * Helper function for generating text with header & body for tour modal.
 *
 * @param header
 * @param body
 * @param className
 * @param classHeader
 * @param classBody
 * @param style
 */
export function tourText({
	header,
	body,
	className = '',
	classHeader = '',
	classBody = '',
	style = '',
}: {
	header?: string;
	body?: string;
	className?: string;
	classHeader?: string;
	classBody?: string;
	style?: string;
}): string {
	return `
      <div class='flex-column ${className}' style='${style}'>
        ${header ? `<h3 class='m-0 mb-16 ${classHeader}'>${header}</h3>` : ''}
        ${body ? `<p class='${classBody}'>${body}</p>` : ''}
      </div>
	`;
}

const TOUR_HIDE_KEY = 'tour';

/**
 * Set flag that controls showing the tour when user arrives on platform
 */
export function tourSetToHide(value: boolean): void {
	localStorage.setItem(TOUR_HIDE_KEY, JSON.stringify(value));
}

/**
 * Gets flag that controls showing the tour when user arrives on platform
 */
export function tourGetToHide(): boolean {
	const toShow = localStorage.getItem(TOUR_HIDE_KEY);
	return !!JSON.parse(toShow);
}

export const tourTextClose = (): string => t('tour.closeButton');
export const tourTextBack = (): string => t('tour.backButton');
export const tourTextNext = (): string => t('tour.nextButton');
export const tourTextComplete = (): string => t('tour.completeButton');

export const tourButtonClose = (): Shepherd.Step.StepOptionsButton => ({
	text: tourTextClose(),
	classes: 'shepherd-button__close',
	action() {
		this.cancel();
	},
});

export const tourButtonBack = (): Shepherd.Step.StepOptionsButton => ({
	text: tourTextBack(),
	action() {
		this.back();
	},
});

export const tourButtonNext = (): Shepherd.Step.StepOptionsButton => ({
	text: tourTextNext(),
	action() {
		this.next();
	},
});

export const tourButtonComplete = (): Shepherd.Step.StepOptionsButton => ({
	text: tourTextComplete(),
	action() {
		this.complete();
	},
});
