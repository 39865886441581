
import { defineComponent, onMounted, PropType, watch } from 'vue';
import {
	Chart,
	ChartDataset,
	ChartEvent,
	LegendElement,
	LegendItem,
	registerables,
} from 'chart.js';
import { range, t } from '@/utils/utils';
import { XY } from '@/models/XY.model';
import { CONFIG_BAR_CHART, CONFIG_BAR_LABEL } from '@/utils/constants.utils';
import { Filters } from '@/models/Filters.model';

Chart.register(...registerables);

enum ComponentEvent {
	CHART_UPDATED = 'chartUpdated',
}

const CHAT_ID = 'chart-id';

const defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;

/**
 * This filter will show only none base lines(ens, ens25, ens75) & last base line.
 *
 * @param item
 */
const legendFilter = (item: LegendItem): boolean => {
	const { text, datasetIndex } = item;
	const lastBaseLine = 10;
	const toHideBasicLine = text === t('mapDetails.basic') && datasetIndex !== lastBaseLine;
	const toHideBackground = text === CONFIG_BAR_LABEL;
	const hide = toHideBasicLine || toHideBackground;
	return !hide;
};

/**
 * New handler will hide all base lines in chart on one click on legend label
 *
 * @param e
 * @param item
 * @param legend
 */
const newLegendClickHandler = (
	e: ChartEvent,
	item: LegendItem,
	legend: LegendElement<'line'>
): void => {
	const { datasetIndex } = item;
	if (datasetIndex <= 2) {
		defaultLegendClickHandler.call(legend, e, item, legend);
		return;
	}

	const { chart } = legend;

	range({ start: 3, end: 10 })
		.map((i) => chart.getDatasetMeta(i))
		.forEach((meta) => {
			// eslint-disable-next-line no-param-reassign
			meta.hidden = meta.hidden === null ? !chart.data.datasets[datasetIndex].hidden : null;
		});

	chart.update();
};

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapChart',
	props: {
		dataset: {
			required: false,
			type: Array as PropType<ChartDataset<'line' | 'bar'>[]>,
			default: () => [],
		},
		filters: {
			required: false,
			type: Object as PropType<Filters>,
			default: () => ({}),
		},
		title: {
			required: false,
			type: String,
			default: () => '',
		},
		yLabel: {
			required: false,
			type: String,
			default: () => '',
		},
	},
	setup(props, context) {
		onMounted(() => {
			const ctx = document.getElementById(CHAT_ID) as HTMLCanvasElement;

			const myChart = new Chart(ctx, {
				type: 'line',
				data: {
					datasets: [
						{
							type: 'line',
							label: '',
							data: [],
						},
					],
				},
				options: {
					responsive: true,
					animation: {
						onComplete: () => {
							context.emit(ComponentEvent.CHART_UPDATED, myChart);
						},
					},
					elements: {
						point: {
							radius: 0,
							hoverRadius: 2.5,
						},
						line: {
							tension: 0.2,
						},
					},
					plugins: {
						legend: {
							position: 'top',
							align: 'end',
							labels: {
								filter: legendFilter,
							},
							onClick: newLegendClickHandler,
						},
						title: {
							display: true,
							text: props.title as string,
							align: 'start',
							position: 'top',
              font: {
                weight: '400',
                size: 14,
              },
              padding: {
                top: 0,
                bottom: 0,
              }
						},
						tooltip: {
							filter: (item) => {
								return item.dataset.label !== CONFIG_BAR_LABEL;
							},
						},
					},
					scales: {
						y: {
							title: {
								display: true,
								text: props.yLabel as string,
							},
						},
					},
					interaction: {
						intersect: false,
						axis: 'x',
					},
				},
			});

			watch(
				() => props.dataset as ChartDataset<'line'>[],
				(datasets) => {
					myChart.data.datasets = datasets || [];
					myChart.options.plugins.title.text = props.title as string;
					myChart.options.scales.y.title.text = props.yLabel as string;

					myChart.update();

					const changePeriod = (props.filters as Filters).changePeriodUuid?.selected;

					/**
					 * If change Period exists, add background on chart for that period.
					 */
					if (changePeriod) {
						const [start, end] = changePeriod.name.split('-').map(Number);
						const xRange = range({ start, end });

						const yValues = myChart.data.datasets.flatMap((dataset) =>
							dataset.data.map((xy) => xy.y)
						);
						const yLabels = myChart.getDatasetMeta(0).yScale.ticks.map((tick) => tick.value);

						const yLabelMax = Math.max(...yLabels, ...yValues);
						const yLabelMin = Math.min(...yLabels, ...yValues);

						// Background above 0 y axis
						const datasetBackgroundAbove: ChartDataset<'line', XY[]> = {
							...CONFIG_BAR_CHART,
							data: xRange.map((xBckg) => new XY({ x: xBckg.toString(), y: yLabelMax })),
						};
						// Background below 0 y axis
						const datasetBackgroundBelow: ChartDataset<'line', XY[]> = {
							...CONFIG_BAR_CHART,
							data: xRange.map((xBckg) => new XY({ x: xBckg.toString(), y: yLabelMin })),
						};

						myChart.data.datasets.push(datasetBackgroundAbove);
						myChart.data.datasets.push(datasetBackgroundBelow);

						myChart.update();
					}
				}
			);
		});

		return {
			CHAT_ID,
		};
	},
});
