import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Area } from '@/enums/Area.enum';
import { LayerName } from '@/enums/LayerName.enum';
import { BASE_URL } from '@/utils/constants.utils';

export const colorLayer = new VectorLayer({
	visible: true,
	source: new VectorSource({
		format: new GeoJSON(),
    url: `${BASE_URL}/data/grid.data.json`,
  }),
});

colorLayer.setProperties({ area: Area.COLOR, name: LayerName.COLOR });
