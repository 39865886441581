export class LegendItem {
	public color: string;
	public min: number;
	public max: number;
	public usage: boolean;

	constructor(obj: LegendItem) {
		this.color = obj.color;
		this.min = obj.min;
		this.max = obj.max;
		this.usage = obj.usage;
	}
}
