
import { defineComponent, PropType } from 'vue';
import { getMapFiltersData } from '@/api/endpoints/map/map.endpoints';
import MapFilters from '@/components/map/MapFilters.vue';
import { MapParams } from '@/api/endpoints/map/map.types';
import { RoutePath } from '@/enums/RoutePath.eunm';
import {
	getFeatureName,
	getFilterParams,
	getSelectedDataVisualizationLabel,
	saveFilterParams,
} from '@/utils/map.utils';
import { capitalizeWord, convertSnakeToCamelCase, falsy, t } from '@/utils/utils';
import { INIT_MAP_PARAMS } from '@/utils/constants.utils';
import MapRenderer from '@/components/map/MapRenderer.vue';
import { CButtonRadioModel } from '@/models/CButtonRadio.model';
import { Area } from '@/enums/Area.enum';
import { MapFiltersReqModel } from '@/models/MapFiltersReq.model';
import { FeatureSelect } from '@/models/FeatureSelect';
import { tourMap, tourMapSteps } from '@/tours/map.tour';
import Shepherd from 'shepherd.js';
import { tourSetToHide, tourGetToHide } from '@/utils/tour.utils';
import { MapFiltersComponent } from '@/models/MapFiltersComponent.model';
import { MapRendererComponent } from '@/models/MapRendererComponent.model';
import { Feature } from 'ol';
import { MapFiltersState } from '@/models/MapFiltersState.model';
import { MapInfoItem } from '@/models/MapInfoItem.model';
import { LonLat } from '@/models/LonLat.model';
import { toLonLat } from 'ol/proj';
import { getCenter } from 'ol/extent';
import { TourStepId } from '@/enums/TourStepId.enum';
import { useMeta } from 'vue-meta';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'Map',
	components: { MapRenderer, MapFilters },
	props: {
		params: {
			required: false,
			type: Object as PropType<MapParams>,
			default: () => ({}),
		},
	},
	setup() {
		useMeta({
			title: t('nav.map'),
		})
	},
	data() {
		return {
			tour: tourMap,
			data: null,
			filters: {},
			area: null,
			areaName: '',
			mapParams: null,
			mapInfoList: [] as MapInfoItem[],
			term: '',
			searchResults: [],
			toShowResults: true,
			filterState: {} as MapFiltersState,
			lat: null,
			lon: null,
		};
	},
	computed: {
		mapFilters(): MapFiltersComponent {
			return this.$refs.mapFilters as MapFiltersComponent;
		},
		mapInfoTitle(): string {
			if (falsy(this.filterState)) {
				return '';
			}

			return getSelectedDataVisualizationLabel({
				state: this.filterState,
				regionName: this.areaName,
			});
		},
	},
	async created() {
		this.mapParams = !falsy(this.params)
			? (this.params as MapParams)
			: getFilterParams() || INIT_MAP_PARAMS;

		this.area = this.mapParams?.area || Area.REGIONS;
		await this.updateData(this.mapParams);
	},
	mounted() {
		this.$nextTick(() => {
			this.initTour();
			if (!tourGetToHide()) {
				this.startTour();
			}
		});
		['complete', 'cancel'].forEach((event) =>
			(Shepherd as any).on(event, () => {
				tourSetToHide(true);
			})
		);
	},
	methods: {
		initTour(): void {
			this.tour.addSteps(tourMapSteps({ tour: this.tour, mapFilters: this.mapFilters }));
		},
		startTour(): void {
			this.mapFilters.closeFilterDrawer();
			this.unselectRegion();
			this.closePopup();
			this.tour.start();
		},
		showTourStep(id: TourStepId): void {
			this.tour.start();
			this.tour.show(id);
		},
		async updateData(obj: MapFiltersReqModel): Promise<void> {
			const {
				data: {
					data,
					meta: { filters },
				},
			} = await getMapFiltersData(obj);

			this.mapParams = { ...obj, area: this.area };
			this.saveMapParams(this.mapParams);

			this.data = data;
			this.filters = filters;

			this.unselectRegion();
			this.closePopup();
		},
		saveMapParams(obj: MapParams): void {
			const { href } = this.$router.resolve({ path: RoutePath.MAP, query: { ...obj } });
			window.history.pushState({}, null, href);
			saveFilterParams(obj);
		},
		onSelectArea(item: CButtonRadioModel): void {
			this.area = item.value as Area;
			this.saveMapParams({ ...this.mapParams, area: this.area });

			this.closePopup();
			this.resetSearchState();
			this.toShowResults = true;
		},
		unselectRegion(): void {
			const mapRenderer = this.$refs.mapRenderer as MapRendererComponent;
			if (mapRenderer) {
				mapRenderer.unselectRegion();
			}
		},
		closePopup(): void {
			const mapRenderer = this.$refs.mapRenderer as MapRendererComponent;
			if (mapRenderer) {
				mapRenderer.closePopup();
			}
		},
		convertNameForDisplay(str: string): string {
			if (!str) {
				return '';
			}

			return convertSnakeToCamelCase({
				str,
				separator: ' ',
			})
				.split(' ')
				.map((word) => capitalizeWord(word))
				.join(' ');
		},
		searchPoint(lonLat: LonLat) {
			const mapRenderer = this.$refs.mapRenderer as MapRendererComponent;

			if (!mapRenderer) {
				return;
			}

			this.lon = lonLat.lon;
			this.lat = lonLat.lat;
			const feat = mapRenderer.searchPoint(lonLat);
			this.closePopup();
			const selectedList = mapRenderer.select.getFeatures();
			selectedList.push(feat);
			mapRenderer.selectRegion(feat);
		},
		search(term: string): void {
			const mapRenderer = this.$refs.mapRenderer as MapRendererComponent;

			if (!mapRenderer) {
				return;
			}

			this.term = term;
			const features = mapRenderer.search(term);
			this.searchResults = features.map(
				(feature) =>
					new FeatureSelect({
						key: feature.get('shortName'),
						name: this.convertNameForDisplay(getFeatureName(feature)),
						feature,
					})
			);
			this.toShowResults = true;
		},
		selectSearch(item: FeatureSelect): void {
			const mapRenderer = this.$refs.mapRenderer as MapRendererComponent;
			if (!mapRenderer) {
				return;
			}

			this.closePopup();
			const selectedList = mapRenderer.select.getFeatures();
			const { feature } = item;
			selectedList.push(feature);
			mapRenderer.selectRegion(feature);
		},
		resetSearchState(): void {
			this.term = '';
			this.areaName = '';
			this.searchResults = [];
		},
		updateTerm(feature: Feature): void {
			this.term = this.convertNameForDisplay(getFeatureName(feature)) ?? '';
			this.areaName = this.term;
			this.toShowResults = false;
		},
		updatePoint(feature: Feature): void {
			const center = getCenter(feature.getGeometry().getExtent());
			[this.lon, this.lat] = toLonLat(center);
		},
		clearLonLat(): void {
			this.lon = null;
			this.lat = null;
		},
		updateMapInfo(filterState: MapFiltersState): void {
			this.filterState = filterState;
			const {
				variablesSelectedLabel,
				basePeriodSelected,
				changePeriodSelected,
				scenarioSelected,
				seasonSelectedLabel,
				areaSelectedLabel,
			} = filterState;

			const basePeriodSelectedLabel = basePeriodSelected?.name;
			const changePeriodSelectedLabel = changePeriodSelected?.name;
			const scenarioSelectedLabel = scenarioSelected?.name;

			this.mapInfoList = [
				new MapInfoItem({
					title: t('filters.variable.title'),
					text: variablesSelectedLabel,
				}),
				basePeriodSelectedLabel &&
					new MapInfoItem({
						title: t('filters.period.base.title'),
						text: basePeriodSelectedLabel,
					}),
				changePeriodSelectedLabel &&
					new MapInfoItem({
						title: t('filters.period.change.title'),
						text: changePeriodSelectedLabel,
					}),
				scenarioSelectedLabel &&
					new MapInfoItem({
						title: t('filters.period.scenario.title'),
						text: scenarioSelectedLabel,
					}),
				new MapInfoItem({
					title: t('filters.season.title'),
					text: seasonSelectedLabel,
				}),
				new MapInfoItem({
					title: t('filters.area.title'),
					text: areaSelectedLabel,
				}),
			].filter(Boolean);
		},
		onCloseFilterDrawer(): void {
			this.mapFilters.closeFilterDrawer();
		},
	},
});
