
import { defineComponent, onMounted, PropType, reactive, ref, watch } from 'vue';
import {
	convertMapToCanvas,
	formatCSVName,
	generateLinePattern,
	getFilterDataVisualizationButtons,
	getFirstFeatureByPixel,
	getFeatureName,
	getStyleForFeature,
	setClippingLayer,
	trimCanvas,
	mapRatioScale,
} from '@/utils/map.utils';
import Map from 'ol/Map';
import View from 'ol/View';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Collection, Feature, MapBrowserEvent, Overlay } from 'ol';
import { Geometry } from 'ol/geom';
import { colorLayer } from '@/components/map/layers/color.layer';
import { patternLayer } from '@/components/map/layers/pattern.layer';
import { serbiaLayer } from '@/components/map/layers/serbia.layer';
import { gridLayer } from '@/components/map/layers/grid.layer';
import { regionsLayer } from '@/components/map/layers/regions.layer';
import { townshipsLayer } from '@/components/map/layers/townships.layer';
import { Area } from '@/enums/Area.enum';
import { Fill, Stroke, Style } from 'ol/style';
import { faDownload, faMapMarkerAlt, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DropdownPlacement } from '@/enums/DropdownPlacement.enum';
import MapOptions from '@/components/map/MapOptions.vue';
import VectorLayer from 'ol/layer/Vector';
import BaseLayer from 'ol/layer/Base';
import {
	BASE_URL,
	getConfigLineChartList,
	CSV_PROPS_DATE,
	CSV_PROPS_DEVIATION,
	CSV_PROPS_LINES_BASIC,
	CSV_PROPS_LINES_PRIMARY,
	CSV_PROPS_LINES_SECONDARY,
	STROKE_BLACK_W1,
	STROKE_TRANSPARENT_W0,
} from '@/utils/constants.utils';
import { jsPDF as JsPDF } from 'jspdf';
import { downloadAttachment } from '@/utils/downloadAttachment.util';
import { downloadNetCDFFile, getCSVContent } from '@/api/endpoints/map/map.endpoints';
import {
	clone,
	convertCSVTextToArray,
	convertSnakeToCamelCase,
	convertUnitToPoints,
	debounce,
	downloadWithPattern,
	falsy,
	getFilenameFromHeaders,
	t,
} from '@/utils/utils';
import { Select } from 'ol/interaction';
import { click, pointerMove } from 'ol/events/condition';
import MapDetails from '@/components/map/MapDetails.vue';
import { XY } from '@/models/XY.model';
import { CSVModModel } from '@/models/CSVMod.model';
import { ChartDataset } from 'chart.js';
import { ChartDatasetConfig } from '@/models/ChartDatasetConfig';
import { FilterDataType } from '@/enums/FilterDataType.enum';
import { MapFilterResData, MapParams } from '@/api/endpoints/map/map.types';
import { ColorRange } from '@/models/ColorRange.model';
import { LegendItem } from '@/models/LegendItem.model';
import MapLegend from '@/components/map/MapLegend.vue';
import html2canvas from 'html2canvas';
import { Filters } from '@/models/Filters.model';
import { LayerName } from '@/enums/LayerName.enum';
import MapPopup from '@/components/map/MapPopup.vue';
import { MapPopupData } from '@/models/MapPopupData.model';
import OverlayPositioning from 'ol/OverlayPositioning';
import { Layer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { TourSelector } from '@/enums/TourSelector.enum';
import { Source } from 'ol/source';
import { FeatureLike } from 'ol/Feature';
import MapInfo from '@/components/map/MapInfo.vue';
import { MapInfoItem } from '@/models/MapInfoItem.model';
import { LonLat } from '@/models/LonLat.model';
import { containsCoordinate } from 'ol/extent';
import MapFooter from '@/components/map/MapFooter.vue';
import { FilterVisualization } from '@/enums/FilterVisualization.enum';

enum ComponentEvent {
	SELECT_REGION = 'selectRegion',
	UNSELECT_REGION = 'unselectRegion',
}

const ANIMATION_MS = 250;

const MAP_ID = 'map-id';
const LEGEND_ID_FOR_IMG = 'legend-id-for-img';
const MAP_INFO_ID_FOR_IMG = 'map-info-id-for-img';
const POPUP_ID = 'map-popup-id-23';

const ALWAYS_SHOW_AREAS = [Area.COLOR, Area.PATTERN, Area.SERBIA];
const MARGIN = 12;

const STYLE_HOVER = new Style({
	fill: new Fill({
		color: 'rgba(220,220,220,0.25)',
	}),
	stroke: new Stroke({
		color: '#000',
		width: 1,
	}),
});

const STYLE_SELECT = new Style({
	fill: new Fill({
		color: 'rgba(220,220,220,0.35)',
	}),
	stroke: new Stroke({
		color: '#000',
		width: 2,
	}),
});

// #region Helper Functions

function getFeaturesForShownArea({
	layers,
	areaToShow,
}: {
	layers: Collection<BaseLayer>;
	areaToShow: string;
}): Feature<Geometry>[] {
	const layer = layers?.getArray().find((l) => l.get('area') === areaToShow) as Layer;
	const source = layer?.getSource() as VectorSource;
	return source?.getFeatures() || [];
}

function searchPointFind({
	layers,
	areaToShow,
	lonLat,
}: {
	layers: Collection<BaseLayer>;
	areaToShow: string;
	lonLat: LonLat;
}): Feature<Geometry> {
	if (falsy(lonLat)) {
		return null;
	}

	const features = getFeaturesForShownArea({ layers, areaToShow });

	return features.find((feat) => {
		const lonLatCoords = fromLonLat(lonLat.list);
		const extentCoords = feat.getGeometry().getExtent();
		return containsCoordinate(extentCoords, lonLatCoords);
	});
}

/**
 * Returns sorted list of Features based on Features name similarity to the term.
 *
 * @param layers
 * @param areaToShow
 * @param term
 *
 */
function searchSort({
	layers,
	areaToShow,
	term,
}: {
	layers: Collection<BaseLayer>;
	areaToShow: string;
	term: string;
}): Feature<Geometry>[] {
	if (!term) {
		return [];
	}
	const convertWord = (word: string): string => {
		return convertSnakeToCamelCase({ str: word, separator: ' ' }).toLocaleLowerCase();
	};
	const searchTerm = convertWord(term);

	const features = getFeaturesForShownArea({ layers, areaToShow });

	return features
		.map((feat) => {
			const searchName = convertWord(getFeatureName(feat));
			feat.set('searchName', searchName);
			return feat;
		})
		.filter((feat) => {
			return feat.get('searchName').includes(searchTerm);
		})
		.sort((featA, featB) => {
			const indexA = featA.get('searchName').indexOf(searchTerm);
			const indexB = featB.get('searchName').indexOf(searchTerm);

			return indexA - indexB;
		});
}

function updateLayerVisibility({
	layers,
	areaToShow,
	alwaysShowAreas = ALWAYS_SHOW_AREAS,
}: {
	layers: Collection<BaseLayer>;
	areaToShow: string;
	alwaysShowAreas?: string[];
}): void {
	layers.forEach((layer) => {
		const layerArea = layer.get('area');
		const toShow = layerArea === areaToShow || alwaysShowAreas.includes(layerArea);
		layer.setVisible(toShow);
	});
}

function updateStyleFromColorArray({
	layer,
	colorArray,
	showStroke = false,
}: {
	layer: VectorLayer;
	colorArray: string[];
	showStroke?: boolean;
}): void {
	layer.setStyle((feature) => {
		const stroke = showStroke ? STROKE_BLACK_W1 : null;

		return getStyleForFeature({
			colorArray,
			feature: feature as Feature<Geometry>,
			...(stroke && { stroke }),
		});
	});
}

function setPatternStyle({ layer, hasArray }: { layer: VectorLayer; hasArray: number[] }): void {
	layer.setStyle((feature) => {
		const index = Number(feature.get('index'));

		if (!hasArray.includes(index)) {
			return null;
		}

		const pattern = generateLinePattern();

		return new Style({
			fill: new Fill({
				color: pattern,
			}),
			stroke: STROKE_TRANSPARENT_W0,
		});
	});
}

async function combineMapAndLegendInCanvas({
	map,
	legend,
	mapInfo,
	margin = MARGIN,
	width,
	height,
}: {
	map: Map;
	legend: HTMLElement;
	mapInfo: HTMLElement;
	margin?: number;
	width?: number;
	height?: number;
}): Promise<HTMLCanvasElement> {
	let mapCanvas = convertMapToCanvas(map);
	mapCanvas = trimCanvas(mapCanvas);
	const { width: widthMap, height: heightMap } = mapCanvas;

	const legendCanvas = await html2canvas(legend);
	const { width: widthLegend, height: heightLegend } = legendCanvas;

	const mapInfoCanvas = await html2canvas(mapInfo);
	const { width: widthMapInfo, height: heightMapInfo } = mapInfoCanvas;

	const canvas = document.createElement('canvas') as HTMLCanvasElement;
	const ctx = canvas.getContext('2d');

	canvas.width = width ?? widthMap + widthLegend + widthMapInfo + margin * 4; // margin * 4 => there is going to be 4 margins between elements and edges of image.
	const biggerHeight = Math.max(heightMap, heightLegend, heightMapInfo);
	canvas.height = height ?? biggerHeight + margin * 2;

	const xMapInfo = margin;
	const yMapInfo = margin;
	ctx.drawImage(mapInfoCanvas, xMapInfo, yMapInfo);

	// Looking form right to left, there is going to be: margin, legend, margin, map.
	const xMap = canvas.width - margin - widthLegend - margin - widthMap;
	const yMap = margin;
	ctx.drawImage(mapCanvas, xMap, yMap);

	const xLegend = canvas.width - margin - widthLegend;
	const yLegend = margin;
	ctx.drawImage(legendCanvas, xLegend, yLegend);

	return canvas;
}

function downloadMapImage({
	map,
	downloadName = 'map',
	legend,
	mapInfo,
}: {
	map: Map;
	downloadName: string;
	legend: HTMLElement;
	mapInfo: HTMLElement;
}): void {
	map.once('rendercomplete', async () => {
		const canvas = await combineMapAndLegendInCanvas({ map, legend, mapInfo });
		downloadWithPattern({
			canvas,
			downloadName,
		});
	});
	map.renderSync();
}

function downloadMapPDF({
	map,
	legend,
	mapInfo,
	margin = MARGIN,
	downloadName = 'map',
}: {
	map: Map;
	legend: HTMLElement;
	mapInfo: HTMLElement;
	margin?: number;
	downloadName: string;
}): void {
	map.once('rendercomplete', async () => {
		const marginPt = convertUnitToPoints(margin, 'px');

		const canvasLegend = await html2canvas(legend);
		const heightLegend = convertUnitToPoints(canvasLegend.height, 'px');
		const widthLegend = convertUnitToPoints(canvasLegend.width, 'px');

		const canvasMapInfo = await html2canvas(mapInfo);
		const heightMapInfo = convertUnitToPoints(canvasMapInfo.height, 'px');

		let canvasMap = convertMapToCanvas(map);
		canvasMap = trimCanvas(canvasMap);
		let widthMap = convertUnitToPoints(canvasMap.width, 'px');
		let heightMap = convertUnitToPoints(canvasMap.height, 'px');

		const pdf = new JsPDF({
			unit: 'pt',
			format: 'a4',
		});

		const widthPDF = pdf.internal.pageSize.getWidth() - marginPt * 2;
		const heightPDF = pdf.internal.pageSize.getHeight() - marginPt * 3;

		let ratioW = 1;
		let ratioH = 1;

		if (widthPDF < widthMap) {
			ratioW = widthMap / widthPDF;
		}
		if (heightPDF < heightMap) {
			ratioH = heightMap / heightPDF;
		}
		const ratio = Math.max(ratioW, ratioH);

		widthMap /= ratio;
		heightMap /= ratio;

		const imageMap = canvasMap.toDataURL();
		const imageLegend = canvasLegend.toDataURL();
		const imageMapInfo = canvasMapInfo.toDataURL();

		const heightBiggerElement = Math.max(heightMapInfo, heightLegend);

		const canFitOnePage = heightPDF > heightMap + heightBiggerElement;

		const xMapInfo = marginPt;
		const yMapInfo = marginPt;

		const xLegend = widthPDF - widthLegend + marginPt; // it is "+ margin" because widthPDF is without margins.
		const yLegend = marginPt;

		if (canFitOnePage) {
			const xMap = marginPt + widthPDF / 2 - widthMap / 2;
			const yMap = marginPt + heightBiggerElement + marginPt;

			pdf.addImage(imageMapInfo, 'PNG', xMapInfo, yMapInfo, null, null);
			pdf.addImage(imageLegend, 'PNG', xLegend, yLegend, null, null);
			pdf.addImage(imageMap, 'PNG', xMap, yMap, widthMap, heightMap);
		} else {
			const xMap = marginPt + widthPDF / 2 - widthMap / 2;
			const yMap = marginPt + heightPDF / 2 - heightMap / 2;

			pdf.addImage(imageMap, 'PNG', xMap, yMap, widthMap, heightMap);
			pdf.addPage();
			pdf.addImage(imageMapInfo, 'PNG', xMapInfo, yMapInfo, null, null);
			pdf.addImage(imageLegend, 'PNG', xLegend, yLegend, null, null);
		}

		pdf.save(`${downloadName}.pdf`);
	});
	map.renderSync();
}

function convertCSVModToDataset({ csv }: { csv: string }): ChartDataset<'line', XY[]>[] {
	const csvArr = convertCSVTextToArray<CSVModModel>({
		csvTxt: csv,
		// It is very important not to change order of propNames since they reflect order of props in csv
		propNames: [
			...CSV_PROPS_DATE,
			...CSV_PROPS_LINES_BASIC,
			...CSV_PROPS_LINES_PRIMARY,
			...CSV_PROPS_LINES_SECONDARY,
		],
	});

	const mapToX = [
		...CSV_PROPS_LINES_PRIMARY,
		...CSV_PROPS_LINES_SECONDARY,
		...CSV_PROPS_LINES_BASIC,
	];

	const dict: Record<string, XY[]> = {};

	let x: string = null;
	let y: number = null;
	let xy: XY = null;
	let value: string = null;

	csvArr.forEach((obj) => {
		const { year } = obj;

		x = year ? year.toString() : null;
		y = null;
		xy = null;

		mapToX.forEach((key) => {
			value = obj[key] as string;
			y = value && Number(value);
			xy = { x, y };

			if (!dict[key]) {
				dict[key] = [];
			}
			dict[key].push(xy);
		});
	});

	const configArr = getConfigLineChartList();

	const datasets = Object.entries(dict).map(([key, array]) => {
		const config = clone(configArr.find((c) => c.props.includes(key)) ?? {});
		delete config.props;
		const dataset: ChartDataset<'line', XY[]> = {
			...config,
			data: array,
		};
		return dataset;
	});

	return datasets;
}

function convertCSVObsToDataset({
	csv,
	visualization,
}: {
	csv: string;
	visualization: string;
}): ChartDataset<'line', XY[]>[] {
	const dataArr = convertCSVTextToArray<CSVModModel>({
		csvTxt: csv,
		propNames: [...CSV_PROPS_DATE, ...CSV_PROPS_DEVIATION],
	}).map(({ year, deviation }) => {
		const x = year ? year.toString() : null;
		const y = deviation && Number(deviation);
		return { x, y } as XY;
	});

	const isChange = visualization === FilterVisualization.PRO;

	const config: ChartDatasetConfig = {
		type: 'line',
		label: isChange ? t('mapDetails.deviation') : t('mapDetails.value'),
		borderWidth: 2,
		backgroundColor: 'rgb(255,107,107)',
		borderColor: 'rgb(255,107,107)',
	};
	const dataset = {
		...config,
		data: dataArr,
	} as ChartDataset<'line', XY[]>;

	return [dataset];
}

const formatLegendArray = (colorRange: ColorRange[]) => {
	return colorRange.map((range) => {
		const color = `rgb(${range.color})`;
		const { min, max, usage } = range;
		return new LegendItem({ color, min, max, usage });
	});
};

// #endregion Helper Functions

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapRenderer',
	components: { MapFooter, MapInfo, MapPopup, MapLegend, MapDetails, MapOptions },
	props: {
		data: {
			required: true,
			type: Object as PropType<MapFilterResData>,
		},
		filters: {
			required: true,
			type: Object as PropType<Filters>,
		},
		fileId: {
			required: true,
			type: String,
		},
		fileName: {
			required: false,
			type: String,
			default: '',
		},
		gridValues: {
			required: true,
			type: Array as PropType<Array<string>>,
			default: () => [],
		},
		hasValues: {
			required: true,
			type: Array as PropType<Array<number>>,
			default: () => [],
		},
		areaToShow: {
			required: true,
			type: String as PropType<Area>,
		},
		params: {
			required: false,
			type: Object as PropType<MapParams>,
			default: () => ({} as MapParams),
		},
		mapInfoList: {
			required: false,
			type: Array as PropType<MapInfoItem[]>,
			default: () => [],
		},
		mapInfoTitle: {
			required: false,
			type: String,
			default: () => '',
		},
		/**
		 * Pattern Layer is not shown by default so it won't confuse the users.
		 */
		showPatternLayer: {
			required: false,
			type: Boolean,
			default: () => false,
		},
	},
  emits: [ComponentEvent.SELECT_REGION, ComponentEvent.UNSELECT_REGION],
	setup(props, context) {
		let map: Map;

		const selectFilter = (feat: FeatureLike, layer: Layer<Source>): boolean => {
			const layerName = layer.get('name');
			const dontSelect = [LayerName.COLOR, LayerName.PATTERN];
			return !dontSelect.includes(layerName);
		};

		const select: Select = new Select({
			condition: click,
			filter: selectFilter,
			style: null,
		});

		// select interaction for "pointermove"/"hover"
		const selectHover = new Select({
			condition: pointerMove,
			filter: selectFilter,
		});

		const showPopover = ref<boolean>(false);
		const selectedFeatShortName = ref<string | null>(null);
		const selectedFeatName = ref<string | null>(null);
		const chartDatasets = ref<ChartDataset<'line', XY[]>[]>([]);
		const chartTitle = ref<string>('');

		let mapData = reactive<MapFilterResData>(props.data as MapFilterResData);
		const legendArray = ref<LegendItem[]>(formatLegendArray(mapData?.colorRange));
		const legendUnit = ref<string>(mapData?.unit || '');

		// Footer props
		const footerLon = ref<number>(null);
		const footerLat = ref<number>(null);
		const footerRegionName = ref<string>(null);
		const footerMapRatio = ref<number>(null);

		let filters = reactive<Filters>(props.filters as Filters);

		const popup = ref<Overlay | null>(null);
		const popupData = ref<MapPopupData | Record<string, unknown>>({});

		const showCursorPointer = ref<boolean>(false);

		updateStyleFromColorArray({
			layer: colorLayer,
			colorArray: props.gridValues as string[],
			showStroke: props.areaToShow === Area.POINT,
		});

		if (props.showPatternLayer) {
			setPatternStyle({
				layer: patternLayer,
				hasArray: props.hasValues as number[],
			});
		}

		watch(
			[() => props.gridValues, () => props.hasValues, () => props.areaToShow],
			([gridVals, hasVals, area]) => {
				updateStyleFromColorArray({
					layer: colorLayer,
					colorArray: gridVals as string[],
					showStroke: area === Area.POINT,
				});
				if (props.showPatternLayer) {
					setPatternStyle({
						layer: patternLayer,
						hasArray: hasVals as number[],
					});
				}
				updateLayerVisibility({
					layers: map.getLayers(),
					areaToShow: area as string,
				});
				map.renderSync();
				map.render();
			}
		);

		watch(
			() => props.data,
			(data) => {
				mapData = reactive<MapFilterResData>(data as MapFilterResData);
				legendArray.value = formatLegendArray(mapData?.colorRange);
				legendUnit.value = mapData.unit;
			}
		);

		watch(
			() => props.filters,
			(filtersData) => {
				filters = reactive<Filters>(filtersData as Filters);
			}
		);

		let selectFeat: Feature<Geometry>;

		const selectRegion = async (feat: Feature): Promise<void> => {
			// eslint-disable-next-line no-unused-expressions
			selectFeat?.setStyle(undefined);
			selectFeat = feat || selectFeat;

			if (!feat) {
				selectedFeatShortName.value = null;
				selectedFeatName.value = null;
				context.emit(ComponentEvent.UNSELECT_REGION);
				return;
			}

			selectedFeatShortName.value = feat.get('shortName');
			selectedFeatName.value = getFeatureName(feat);

			if (!selectedFeatShortName.value) {
				return;
			}

			feat.setStyle(STYLE_SELECT);

			const {
				data,
				headers: { xFilename },
			} = await getCSVContent({
				uuid: props.fileId as string,
				region: selectedFeatShortName.value,
			});

			let regionName = selectedFeatName.value
				? convertSnakeToCamelCase({
						str: selectedFeatName.value.toLowerCase(),
						separator: ' ',
				  })
				: '';
			regionName = regionName ? `${regionName} / ` : '';

			chartTitle.value = `${regionName}${formatCSVName(xFilename)}`;

			if (filters.dataType.selected === FilterDataType.MOD) {
				chartDatasets.value = convertCSVModToDataset({ csv: data });
			}

			if (filters.dataType.selected === FilterDataType.OBS) {
				chartDatasets.value = convertCSVObsToDataset({
					csv: data,
					visualization: filters.visualization.selected,
				});
			}

			context.emit(ComponentEvent.SELECT_REGION, feat);
		};

		const setFooterRatio = debounce<() => void>(() => {
			footerMapRatio.value = mapRatioScale({ map });
		}, ANIMATION_MS);

		onMounted(() => {
			setClippingLayer({
				clippedLayer: colorLayer,
				maskLayer: serbiaLayer,
			});
			if (props.showPatternLayer) {
				setClippingLayer({
					clippedLayer: patternLayer,
					maskLayer: serbiaLayer,
				});
			}
			setClippingLayer({
				clippedLayer: gridLayer,
				maskLayer: serbiaLayer,
			});

			const popupEle = document.getElementById(POPUP_ID);
			popup.value = new Overlay({
				element: popupEle,
				autoPan: true,
				autoPanAnimation: {
					duration: ANIMATION_MS,
				},
				positioning: OverlayPositioning.BOTTOM_CENTER,
			});

			map = new Map({
				target: MAP_ID,
				// Order of first 3 layers are important.
				layers: [
					colorLayer,
					props.showPatternLayer ? patternLayer : null,
					serbiaLayer,
					gridLayer,
					regionsLayer,
					townshipsLayer,
				].filter(Boolean),
				overlays: [popup.value as Overlay],
				view: new View({
					center: fromLonLat([21.212, 44.062]),
					zoom: 7.5,
					minZoom: 7,
					maxZoom: 12.5,
				}),
				controls: [],
			});

			updateLayerVisibility({
				layers: map.getLayers(),
				areaToShow: props.areaToShow as string,
			});

			map.on('singleclick', async (evt: MapBrowserEvent) => {
				if (!showPopover.value) {
					popup.value.setPosition(null);
					return;
				}

				const { pixel } = evt;
				const { coordinate } = evt;
				const [lon, lat] = toLonLat(coordinate);

				const serbiaFeat = await getFirstFeatureByPixel({
					pixel,
					layer: serbiaLayer,
				});

				const notInSerbia = !serbiaFeat;
				if (notInSerbia) {
					popup.value.setPosition(null);
					return;
				}

				const firstSquare = await getFirstFeatureByPixel({
					pixel,
					layer: colorLayer,
					movePixelBy: [23, -23, 32, -32],
				});

				if (!firstSquare) {
					popup.value.setPosition(null);
					return;
				}

				const item = filters.variableUuid.selected;

				const title = t(`filters.variable.${item.name}Title`);
				const name = t(`filters.variable.${item.name}`).toUpperCase();

				const subtitle = getFilterDataVisualizationButtons().find((btn) => {
					const dataType = filters.dataType.selected;
					const visualization = filters.visualization.selected;
					return btn.dataType === dataType && btn.visualization === visualization;
				})?.label;

				const index = firstSquare.get('index');
				const value = mapData.valueGrid[index];

				const info = `${name}: ${value}`;

				popupData.value = new MapPopupData({
					title,
					subtitle,
					lat: lat.toFixed(4),
					lon: lon.toFixed(4),
					info,
				});

				popup.value.setPosition(coordinate);
			});

			map.addInteraction(selectHover);
			map.addInteraction(select);

			/**
			 * Used for Serbia, Region, Township and Point
			 */
			select.on('select', async () => {
				const [feat] = select.getFeatures().getArray();
				await selectRegion(feat);
			});

			select.on('unselect', () => {
				select.getFeatures().clear();
				// eslint-disable-next-line no-unused-expressions
				selectFeat?.setStyle(undefined);
				selectFeat = null;
				selectedFeatShortName.value = null;
				selectedFeatName.value = null;

				context.emit(ComponentEvent.UNSELECT_REGION);
			});

			selectHover.on('select', () => {
				const [feat] = selectHover.getFeatures().getArray();
				footerRegionName.value = '';
				if (feat) {
					feat.setStyle(STYLE_HOVER);
					footerRegionName.value = convertSnakeToCamelCase({
						str: getFeatureName(feat),
						separator: ' ',
					});
				}
				// eslint-disable-next-line no-unused-expressions
				selectFeat?.setStyle(STYLE_SELECT);
			});

			map.once('rendercomplete', () => setFooterRatio());
			map.getView().on('change:resolution', () => setFooterRatio());

			const manageCursorPointer = debounce<(event: MapBrowserEvent<UIEvent>) => void>((event) => {
				showCursorPointer.value = false;

				const { coordinate, pixel } = event;
				[footerLon.value, footerLat.value] = toLonLat(coordinate);

				map.forEachFeatureAtPixel(pixel, (feature, layer) => {
					if (layer.get('name') === LayerName.SERBIA) {
						showCursorPointer.value = true;
					}
				});
			}, 100);

			map.on('pointermove', manageCursorPointer);
		});

		const search = (term: string): Feature<Geometry>[] => {
			return searchSort({
				layers: map.getLayers(),
				areaToShow: props.areaToShow as string,
				term,
			});
		};

		const searchPoint = (lonLat: LonLat): Feature<Geometry> => {
			return searchPointFind({
				layers: map.getLayers(),
				areaToShow: props.areaToShow as string,
				lonLat,
			});
		};

		const closePopup = (): void => {
			popup.value.setPosition(null);
		};

		const unselectRegion = (): void => {
			select.dispatchEvent('unselect');
		};

		const updateZoomBy = (num: number): void => {
			map.getView().animate({
				zoom: map.getView().getZoom() + num,
				duration: ANIMATION_MS,
			});
		};

		const onShowPopover = (): void => {
			showPopover.value = !showPopover.value;
		};

		const onDownloadImage = (): void => {
			const downloadName = mapData.fileName;
			const legend = document.getElementById(LEGEND_ID_FOR_IMG);
			const mapInfo = document.getElementById(MAP_INFO_ID_FOR_IMG);
			downloadMapImage({ map, downloadName, legend, mapInfo });
		};

		const onDownloadPDF = (): void => {
			const downloadName = mapData.fileName;
			const legend = document.getElementById(LEGEND_ID_FOR_IMG);
			const mapInfo = document.getElementById(MAP_INFO_ID_FOR_IMG);
			downloadMapPDF({ map, downloadName, legend, mapInfo });
		};

		const onDownloadFile = async (): Promise<void> => {
			const { data, headers } = await downloadNetCDFFile({ uuid: props.fileId as string });
			const name = getFilenameFromHeaders(headers) || mapData.fileName;
			downloadAttachment(data, name);
		};

		return {
			TourSelector,
			MAP_ID,
			LEGEND_ID_FOR_IMG,
			MAP_INFO_ID_FOR_IMG,
			POPUP_ID,
			search,
			searchPoint,
			updateZoomBy,
			selectRegion,
			unselectRegion,
			closePopup,
			zoomStep: 0.6,
			showPopover,
			onShowPopover,
			onDownloadImage,
			onDownloadPDF,
			onDownloadFile,
			faPlus,
			faMinus,
			faMapMarkerAlt,
			faDownload,
			DropdownPlacement,
			select,
			selectedFeatShortName,
			selectedFeatName,
			chartDatasets,
			chartTitle,
			legendUnit,
			legendArray,
			popup,
			popupData,
			showCursorPointer,
			footerLon,
			footerLat,
			footerRegionName,
			footerMapRatio,
		};
	},
});
