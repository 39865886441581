import { AxiosPromise } from 'axios';
import {
	CSVContentReq,
	DownloadNetCDFReq,
	MapFilterRes,
} from '@/api/endpoints/map/map.types';
import { http } from '@/api/http.api';
import { convertCamelToSnakeCaseObjectKeys } from '@/utils/utils';
import { MapFiltersReqModel } from '@/models/MapFiltersReq.model';

export function getMapFiltersData(req: MapFiltersReqModel): AxiosPromise<MapFilterRes> {
	const obj = new MapFiltersReqModel(req);
	const params = convertCamelToSnakeCaseObjectKeys(obj as any);
	return http.get('/netcdf/filter', { params });
}

export function downloadNetCDFFile({ uuid }: DownloadNetCDFReq): AxiosPromise<ArrayBuffer> {
	return http.get(`/netcdf/${uuid}/download`, {
		responseType: 'arraybuffer',
	});
}

export function getCSVContent({ uuid, region }: CSVContentReq): AxiosPromise<string> {
	return http.get(`/csv/${uuid}/${region}`);
}

export function downloadCSVContent({ uuid, region }: CSVContentReq): AxiosPromise<ArrayBuffer> {
	return http.get(`/csv/${uuid}/${region}/download`, {
		responseType: 'arraybuffer',
	});
}
