import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Style } from 'ol/style';
import { Area } from '@/enums/Area.enum';
import { LayerName } from '@/enums/LayerName.enum';
import { BASE_URL, FILL_TRANSPARENT, STROKE_BLACK_W1 } from '@/utils/constants.utils';

const style = new Style({
	fill: FILL_TRANSPARENT,
	stroke: STROKE_BLACK_W1,
});

export const regionsLayer = new VectorLayer({
	visible: true,
	source: new VectorSource({
		format: new GeoJSON(),
		url: `${BASE_URL}/data/regions.data.json`,
	}),
	style,
});

regionsLayer.setProperties({ area: Area.REGIONS, name: LayerName.REGIONS });
