
import { defineComponent, PropType, ref, watch } from 'vue';
import CButtonIcon from '@/components/shared/CButtonIcon.vue';
import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ButtonIconType } from '@/enums/ButtonIconType.enum';
import { ButtonIconColor } from '@/enums/ButtonIconColor.enum';
import { ButtonType } from '@/enums/ButtonType.enum';
import MapDownloadDropdown from '@/components/map/MapDownloadDropdown.vue';
import MapChart from '@/components/map/MapChart.vue';
import { downloadCSVContent } from '@/api/endpoints/map/map.endpoints';
import { downloadWithPattern, getFilenameFromHeaders } from '@/utils/utils';
import { downloadAttachment } from '@/utils/downloadAttachment.util';
import { jsPDF as JsPDF } from 'jspdf';
import { Chart, ChartDataset } from 'chart.js';
import { BASE_URL } from '@/utils/constants.utils';
import { DetailsTabName } from '@/enums/DetailsTabName.enum';
import { Filters } from '@/models/Filters.model';
import { Area } from '@/enums/Area.enum';

enum ComponentEvent {
	HIDE_DETAILS = 'hideDetails',
	SHOW_DETAILS = 'showDetails',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapDetails',
	components: { MapChart, MapDownloadDropdown, CButtonIcon },
	props: {
		filters: {
			required: true,
			type: Object as PropType<Filters>,
		},
		areaToShow: {
			required: false,
			type: String as PropType<Area>,
		},
		fileId: {
			required: true,
			type: String,
		},
		region: {
			required: true,
      type: null as unknown as PropType<string | null>,
      validator: (v: never) => typeof v === 'string' || v === null,
		},
		regionName: {
			required: true,
      type: null as unknown as PropType<string | null>,
      validator: (v: never) => typeof v === 'string' || v === null,
		},
		showDetails: {
			required: false,
			type: Boolean,
			default: true,
		},
		datasets: {
			required: false,
			type: Array as PropType<ChartDataset<'line'>[]>,
			default: () => [],
		},
		activeTab: {
			required: false,
			type: Number as PropType<DetailsTabName>,
			default: () => DetailsTabName.TIME_SERIES,
		},
		chartTitle: {
			required: false,
			type: String,
			default: () => '',
		},
		yLabel: {
			required: false,
			type: String,
			default: () => '',
		},
	},
	setup(props, context) {
		const visibility = ref<boolean>(props.showDetails);
		const tabToShow = ref<DetailsTabName>(props.activeTab as DetailsTabName);
		let myChart: Chart = null;

		const setVisibility = (value: boolean): void => {
			visibility.value = value;
			context.emit(value ? ComponentEvent.SHOW_DETAILS : ComponentEvent.HIDE_DETAILS);
		};
		watch(() => props.showDetails, setVisibility);

		const setTabToShow = (tabName: DetailsTabName): void => {
			tabToShow.value = tabName;
		};
		watch(
			() => props.activeTab,
			(val) => setTabToShow(val as DetailsTabName)
		);

		const onChartUpdated = (chart: Chart): void => {
			myChart = chart;
		};

		const onDownloadImage = (): void => {
			if (!myChart) {
				return;
			}
			const { canvas } = myChart;

			downloadWithPattern({
				canvas,
				downloadName: props.chartTitle as string,
			});
		};

		const onDownloadPDF = (): void => {
			if (!myChart) {
				return;
			}
			const { canvas } = myChart;
			const imageData = canvas.toDataURL();

			const pdf = new JsPDF({ orientation: 'landscape', unit: 'mm' });
			const width = pdf.internal.pageSize.getWidth();
			const height = 41;

			pdf.addImage(imageData, 'PNG', 0, 23, width, height);
			pdf.save(`${props.chartTitle}.pdf`);
		};

		const onDownloadFile = async (): Promise<void> => {
			const { data, headers } = await downloadCSVContent({
				uuid: props.fileId as string,
				region: props.region as string,
			});
			const name = getFilenameFromHeaders(headers) || 'chart';

			downloadAttachment(data, name);
		};

		return {
			ButtonType,
			ButtonIconType,
			ButtonIconColor,
			faDownload,
			faTimes,
			TabName: DetailsTabName,
			tabToShow,
			setTabToShow,
			visibility,
			setVisibility,
			onDownloadImage,
			onDownloadPDF,
			onDownloadFile,
			onChartUpdated,
		};
	},
});
