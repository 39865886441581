import { MapParams } from '@/api/endpoints/map/map.types';
import { FilterSeason } from '@/enums/FilterSeason.enum';
import { FilterVisualization } from '@/enums/FilterVisualization.enum';
import { FilterDataType } from '@/enums/FilterDataType.enum';

/**
 *  This model is used like filter and only sets proper params for BE
 */
export class MapFiltersReqModel implements MapParams {
	public basePeriodUuid: string;
	public changePeriodUuid: string;
	public dataType: FilterDataType;
	public heating: string;
	public scenarioUuid: string;
	public season: FilterSeason;
	public variableUuid: string;
	public visualization: FilterVisualization;

	constructor(obj: MapParams) {
		this.dataType = obj.dataType;
		this.visualization = obj.visualization;
		this.variableUuid = obj.variableUuid;
		this.basePeriodUuid = obj.basePeriodUuid;
		this.changePeriodUuid = obj.changePeriodUuid;
		this.scenarioUuid = obj.scenarioUuid;
		this.heating = obj.heating;
		this.season = obj.season;
	}
}
