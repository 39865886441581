
import { defineComponent } from 'vue';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CInfoRow',
	props: {
		title: {
			required: true,
			type: String,
		},
		titleInfo: {
			required: false,
			type: String,
			default: () => '',
		},
		disabled: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		active: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		hasDividerStart: {
			required: false,
			type: Boolean,
			default: false,
		},
		hasDividerEnd: {
			required: false,
			type: Boolean,
			default: true,
		},
		clickAction: {
			required: false,
			type: Function,
			default: () => null,
		},
	},
});
