
import { defineComponent, PropType } from 'vue';
import { t } from '@/utils/utils';
import { LegendItem } from '@/models/LegendItem.model';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapLegend',
	props: {
		list: {
			required: true,
			type: Array as PropType<LegendItem[]>,
		},
		unit: {
			required: false,
			type: String,
			default: () => '',
		},
		toMarkUnused: {
			required: false,
			type: Boolean,
			default: () => false,
		},
	},
	computed: {
		/**
		 * Filters item if both min and max are not numbers.
		 */
		displayList(): LegendItem[] {
			return (this.list as LegendItem[]).filter(
				({ min, max }) => !(!Number.isFinite(min) && !Number.isFinite(max))
			);
		},
	},
	methods: {
		formatMinMax(min: number, max: number): string {
			if (!Number.isFinite(min) && Number.isFinite(max)) {
				return `< ${max}`;
			}
			if (!Number.isFinite(max) && Number.isFinite(min)) {
				return `> ${min}`;
			}

			return `${min} ${t('word.to')} ${max}`;
		},
		isUnused(item: LegendItem): boolean {
			return this.toMarkUnused && !item.usage;
		},
	},
});
