export class FilterDataVisualizationButton {
	public selected: string;
	public dataType: string;
	public visualization: string;
	public label: string;

	constructor(obj: FilterDataVisualizationButton) {
		this.label = obj.label;
		this.selected = obj.selected;
		this.dataType = obj.dataType;
		this.visualization = obj.visualization;
	}
}
