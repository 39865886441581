
import { defineComponent } from 'vue';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CIconLeftRight',
	props: {
		isLeft: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		disabled: {
			required: false,
			type: Boolean,
			default: () => false,
		},
	},
	setup() {
		return {
			faChevronLeft,
		};
	},
});
