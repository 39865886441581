import Shepherd from 'shepherd.js';
import {
	tourButtonBack,
	tourButtonClose,
	tourButtonComplete,
	tourButtonNext,
	tourSelect,
	tourText,
	tourTextBack,
	tourTextNext,
} from '@/utils/tour.utils';
import { t } from '@/utils/utils';
import { TourSelector } from '@/enums/TourSelector.enum';
import { MapFiltersComponent } from '@/models/MapFiltersComponent.model';
import { FilterName } from '@/enums/FilterName.enum';
import { getAreaButtonList } from '@/utils/constants.utils';
import { Area } from '@/enums/Area.enum';
import { TourStepId } from '@/enums/TourStepId.enum';
import Step = Shepherd.Step;

export const tourMap = new Shepherd.Tour({
	defaultStepOptions: {
		scrollTo: true,
		canClickTarget: false,
		cancelIcon: {
			enabled: true,
			label: 'cancel',
		},
		arrow: false,
	},
	useModalOverlay: true,
	keyboardNavigation: false,
});

export const tourMapSteps = ({
	tour,
	mapFilters,
}: {
	tour: Shepherd.Tour;
	mapFilters: MapFiltersComponent;
}): Array<Step | Step.StepOptions> => {
	return [
		// welcome
		{
			id: TourStepId.WELCOME,
			classes: 'mb-16 mx-16',
			text: tourText({
				header: t('tour.map.title'),
				body: t('tour.map.info'),
				style: 'text-align: center;',
			}),
			buttons: [tourButtonClose(), tourButtonNext()],
		},
		// map
		{
			id: TourStepId.MAP,
			text: tourText({
				body: t('tour.map.map'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.MAP),
				on: 'left',
			},
			buttons: [tourButtonBack(), tourButtonNext()],
		},
		// observation
		{
			id: TourStepId.OBSERVATION,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.observation'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.OBSERVATION),
				on: 'right-start',
			},
			buttons: [tourButtonBack(), tourButtonNext()],
		},
		// projection
		{
			id: TourStepId.PROJECTION,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.projection'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.PROJECTION),
				on: 'right-start',
			},
			buttons: [
				tourButtonBack(),
				{
					text: tourTextNext(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.VARIABLE_UUID, true);
						tour.next();
					},
				},
			],
		},
		// variables
		{
			id: TourStepId.VARIABLES,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.variables'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.VARIABLES),
				on: 'right-start',
			},
			buttons: [
				{
					text: tourTextBack(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.VARIABLE_UUID, false);
						tour.back();
					},
				},
				tourButtonNext(),
			],
		},
		// variablesFilter
		{
			id: TourStepId.VARIABLES_FILTER,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.variablesFilter'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.DRAWER),
				on: 'right-start',
			},
			buttons: [tourButtonBack(), tourButtonNext()],
		},
		// period
		{
			id: TourStepId.PERIOD,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.period'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.PERIOD),
				on: 'right-start',
			},
			buttons: [
				{
					text: tourTextBack(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.VARIABLE_UUID, true);
						tour.back();
					},
				},
				{
					text: tourTextNext(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.BASE_PERIOD_UUID, true);
						tour.next();
					},
				},
			],
		},
		// periodFilter
		{
			id: TourStepId.PERIOD_FILTER,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.periodFilter'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.DRAWER),
				on: 'right-start',
			},
			buttons: [tourButtonBack(), tourButtonNext()],
		},
		// season
		{
			id: TourStepId.SEASON,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.season'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.SEASON),
				on: 'right-start',
			},
			buttons: [
				{
					text: tourTextBack(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.BASE_PERIOD_UUID, true);
						tour.back();
					},
				},
				{
					text: tourTextNext(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.SEASON, true);
						tour.next();
					},
				},
			],
		},
		// seasonFilter
		{
			id: TourStepId.SEASON_FILTER,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.seasonFilter'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.DRAWER),
				on: 'right-start',
			},
			buttons: [
				tourButtonBack(),
				{
					text: tourTextNext(),
					action: () => {
						const region = getAreaButtonList().find((btn) => btn.value === Area.REGIONS);
						mapFilters.selectArea(region);
						tour.next();
					},
				},
			],
		},
		// area
		{
			id: TourStepId.AREA,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.area'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.AREA),
				on: 'right-start',
			},
			buttons: [
				{
					text: tourTextBack(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.SEASON, true);
						tour.back();
					},
				},
				{
					text: tourTextNext(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.AREA, true);
						tour.next();
					},
				},
			],
		},
		// areaFilter
		{
			id: TourStepId.AREA_FILTER,
			classes: 'mb-16 mx-16',
			text: tourText({
				body: t('tour.map.areaFilter'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.DRAWER),
				on: 'right-start',
			},
			buttons: [tourButtonBack(), tourButtonNext()],
		},
		// areaSearch
		{
			id: TourStepId.AREA_SEARCH,
			classes: 'mb-16 mx-16 shepherd-element--visible',
      modalOverlayOpeningRadius: 4,
      text: tourText({
				body: t('tour.map.areaSearch'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.AREA_SEARCH),
				on: 'right-start',
			},
			buttons: [tourButtonBack(), tourButtonNext()],
		},
		// areaSearchLonLat
		{
			id: TourStepId.AREA_SEARCH_LON_LAT,
			classes: 'mb-16 mx-16 shepherd-element--visible',
			text: tourText({
				body: t('tour.map.areaSearchLonLat'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.AREA_SEARCH_LON_LAT),
				on: 'right-start',
			},
			buttons: [tourButtonBack(), tourButtonNext()],
		},
		// legend:
		{
			id: TourStepId.LEGEND,
			classes: 'mb-16 mx-16',
      modalOverlayOpeningRadius: 4,
      text: tourText({
				body: t('tour.map.legend'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.LEGEND),
				on: 'left-start',
			},
			buttons: [
				tourButtonBack(),
				{
					text: tourTextNext(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.AREA, false);
						tour.next();
					},
				},
			],
		},
		// zoomIn
		{
			id: TourStepId.ZOOM_IN,
			classes: 'mt-16',
			modalOverlayOpeningRadius: 4,
			text: tourText({
				body: t('tour.map.zoomIn'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.ZOOM_IN),
				on: 'bottom-end',
			},
			buttons: [
				{
					text: tourTextBack(),
					action: () => {
						mapFilters.setVisibilityFilter(FilterName.AREA, true);
						tour.back();
					},
				},
				tourButtonNext(),
			],
		},
		// zoomOut
		{
			id: TourStepId.ZOOM_OUT,
			classes: 'mt-16',
			modalOverlayOpeningRadius: 4,
			text: tourText({
				body: t('tour.map.zoomOut'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.ZOOM_OUT),
				on: 'bottom-end',
			},
			buttons: [tourButtonBack(), tourButtonNext()],
		},
		// pin
		{
			id: TourStepId.PIN,
			classes: 'mt-16',
			modalOverlayOpeningRadius: 4,
			text: tourText({
				body: t('tour.map.pin'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.PIN),
				on: 'bottom-end',
			},
			buttons: [tourButtonBack(), tourButtonNext()],
		},
		// download
		{
			id: TourStepId.DOWNLOAD,
			classes: 'mt-16',
			modalOverlayOpeningRadius: 4,
			text: tourText({
				body: t('tour.map.download'),
			}),
			attachTo: {
				element: tourSelect(TourSelector.DOWNLOAD),
				on: 'bottom-end',
			},
			buttons: [tourButtonBack(), tourButtonComplete()],
		},
	];
};
