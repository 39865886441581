import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Area } from '@/enums/Area.enum';
import { Style } from 'ol/style';
import { LayerName } from '@/enums/LayerName.enum';
import { BASE_URL, FILL_TRANSPARENT, STROKE_BLACK_W1 } from '@/utils/constants.utils';

const style = new Style({
	fill: FILL_TRANSPARENT,
	stroke: STROKE_BLACK_W1,
});

export const serbiaLayer = new VectorLayer({
	visible: true,
	source: new VectorSource({
		format: new GeoJSON(),
		url: `${BASE_URL}/data/serbia.data.json`,
	}),
	style,
});

serbiaLayer.setProperties({ area: Area.SERBIA, name: LayerName.SERBIA });
