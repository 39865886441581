
import { defineComponent, PropType } from 'vue';
import { CDrawerOffset } from '@/models/CDrawerOffset.model';
import { TourSelector } from '@/enums/TourSelector.enum';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'CDrawer',
	props: {
		show: {
			required: false,
			type: Boolean,
			default: false,
		},
		offset: {
			required: false,
			type: Object as PropType<CDrawerOffset>,
			default: { left: '0', top: '0' } as CDrawerOffset,
		},
	},
	setup() {
		return {
			TourSelector,
		};
	},
});
