
import { defineComponent, PropType } from 'vue';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DropdownPlacement } from '@/enums/DropdownPlacement.enum';
import CDropdown from '@/components/shared/CDropdown.vue';
import CButtonIcon from '@/components/shared/CButtonIcon.vue';
import { ButtonIconType } from '@/enums/ButtonIconType.enum';
import { ButtonIconColor } from '@/enums/ButtonIconColor.enum';
import { TourSelector } from '@/enums/TourSelector.enum';

enum ComponentEvent {
	DOWNLOAD_IMAGE = 'downloadImage',
	DOWNLOAD_PDF = 'downloadPDF',
	DOWNLOAD_FILE = 'downloadFile',
}

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapDownloadDropdown',
	components: { CButtonIcon, CDropdown },
	props: {
		buttonIconType: {
			required: false,
			type: String as PropType<ButtonIconType>,
			default: () => ButtonIconType.RAISED,
		},
		buttonIconColor: {
			required: false,
			type: String as PropType<ButtonIconColor>,
			default: () => ButtonIconColor.BASIC,
		},
		hideAnimation: {
			required: false,
			type: Boolean,
			default: false,
		},
		placement: {
			required: false,
			type: String as PropType<DropdownPlacement>,
			default: () => DropdownPlacement.BOTTOM,
		},
	},
	setup(props, context) {
		return {
			TourSelector,
			context,
			faDownload,
			DropdownPlacement,
			ComponentEvent,
		};
	},
	data() {
		return {
			dropdownTriggerActive: false,
		};
	},
});
