export enum TourStepId {
	WELCOME = 'welcome-step-id',

	MAP = 'map-step-id',

	OBSERVATION = 'observation-step-id',
	PROJECTION = 'projection-step-id',

	VARIABLES = 'variables-step-id',
	VARIABLES_FILTER = 'variables-filter-step-id',

	PERIOD = 'period-step-id',
	PERIOD_FILTER = 'period-filter-step-id',

	SEASON = 'season-step-id',
	SEASON_FILTER = 'season-filter-step-id',

	AREA = 'area-step-id',
	AREA_FILTER = 'area-filter-step-id',
	AREA_SEARCH = 'area-search-step-id',
  AREA_SEARCH_LON_LAT = 'area-search-lon-lat-step-id',

	LEGEND = 'legend-step-id',
	ZOOM_IN = 'zoom-in-step-id',
	ZOOM_OUT = 'zoom-out-step-id',
	PIN = 'pin-step-id',
	DOWNLOAD = 'download-step-id',
}
