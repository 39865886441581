export enum TourSelector {
	MAP = 'map',

	OBSERVATION = 'observation',
	PROJECTION = 'projection',

	VARIABLES = 'variables',
	PERIOD = 'period',
	SEASON = 'season',

	AREA = 'area',
	AREA_SEARCH = 'areaSearch',
  AREA_SEARCH_LON_LAT = 'areaSearchLonLat',


	DRAWER = 'drawer',

	LEGEND = 'legend',
	ZOOM_IN = 'zoomIn',
	ZOOM_OUT = 'zoomOut',
	PIN = 'pin',
	DOWNLOAD = 'download',
}
