
import { defineComponent, PropType } from 'vue';
import { MapInfoItem } from '@/models/MapInfoItem.model';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'MapInfo',
	props: {
		title: {
			required: false,
			type: String,
			default: () => '',
		},
		list: {
			required: true,
			type: Array as PropType<MapInfoItem[]>,
			default: () => [],
		},
	},
});
