import { KeyName } from '@/models/KeyName.model';
import { Feature } from 'ol';

export class FeatureSelect extends KeyName {
	feature: Feature;

	constructor({ key, name, feature }: FeatureSelect) {
		super({ key, name });
		this.feature = feature;
	}
}
